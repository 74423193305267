<template>
    <div class="container">
        <select v-model="departement" class="form-select mb-3" style="width: 100%;" required v-on:change="changeDept($event)">
            <option class="w3-white" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                {{ option.text }}
            </option>
        </select>
        <div class="container pt-1 scrlt-y" v-bind:style="'height:'+  (screenHeight-135) +'px;'">
            <div class="row mb-3">
                <div class="col-md-12">
                    <div class="row mb-3">
                        <div class="col-md-6 mb-3">
                            <div class="card">
                                <div class="container row m-2 mt-3">
                                    <div class="col-md-12">
                                        <p class="h9 fw-bold mb-0">Customer Online</p>
                                        <p class="h8 fw-bold">{{card.custon}}</p>
                                        <p class="text-center h2 text-black-50">
                                            <span class="mdi mdi-card-account-details-outline"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 ">
                            <div class="card">
                                <div class="container row m-2 mt-3">
                                    <div class="col-md-12">
                                        <p class="h9 fw-bold mb-0">Customer Offline</p>
                                        <p class="h8 fw-bold">{{card.custoff}}</p>
                                        <p class="text-center h2 text-black-50">
                                            <span class="mdi mdi-card-account-details-outline"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="card p-3">
                            <div class="card-header bg-transparent mb-3 h9 pt-0 fw-bold">
                                Table Customer
                            </div>
                            <table class="table table-hover table-responsive" id="ratio" style="cursor:pointer">
                                <thead>
                                    <tr class="table-head">
                                        <th class="">Nama</th>
                                        <th class="">{{yearSub}}</th>
                                        <th class="">{{year}}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import PieChart from "../chart/PieChart.js";
import moment from "moment";

// import "jquery/dist/jquery.min.js";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'D-Customer',
    components: {
        PieChart
    },
    data() {
        return {
            sales_name : localStorage.username,
            screenHeight : 0,
            day : moment(new Date()).format('DD'),
            month : moment(new Date()).format('MMM'),
            year : moment(new Date()).format('YYYY'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            yearSub : moment().subtract(1, 'year').format('YYYY'),
            card : {
                custon : 0,
                custoff : 0,
            },
            recCharts : [],
            chartData: {},
            chartOptions: {},
            departement : 'SUB',
            dept: [
                { text: 'SURABAYA', value: 'SUB' },
                { text: 'SEMARANG', value: 'SRG' }
            ],
            recsratiocust : [],
            recCust : {
                status : '',
                bulan : '',
                tahun : ''
            },
            recsCust : []
        }
    },
    methods : {
        changeDept(event){
            this.LoadData();
            this.getDataPie();
        },
        showCust(event){
            let self = this;
            axios.post("dashboard/ApiDboard.php",{
                pfunction : 'filtercust',
                dept : self.departement,
                status : self.recCust.status,
                bulan : self.recCust.bulan,
                tahun : self.recCust.tahun
            }).then(function (response) {
                self.recsCust = response.data.rec;
                $('#reccust').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.recsCust,
                    columns: [
                        {"data": "CustID"},
                        {"data": "CustName"},
                        {"data": "Address"},
                        {"data": "Phones"},
                        {"data": "lastdt"}
                    ],
                    columnDefs: [
                    ],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" +
        "<'row'<'col-md-12 h9 mb-3'tr>>" +
        "<'row'<'col-sm-12 col-md-5 h9'i><'col-sm-12 col-md-7'p>>",
                    buttons: [
                        {
                            extend: 'csvHtml5', text: 'Export',
                            className: 'btn btn-primary',
                        }
                    ],
                    // buttons: ['csv']
                });
            });
            event.preventDefault();
            return false;
        },
        LoadData(){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            axios.post("dashboard/ApiDboard.php", {
                pfunction: "ratiocust",
                dept: self.departement
                }).then(function(response) {
                document.getElementById('idLoading').style.display = 'none';
                self.recsratiocust = response.data.rec;

                // Custom function to format numbers as "Rp"
                function toRp(value) {
                    if (!value) return 'Rp 0';
                    return 'Rp ' + parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                }

                $('#ratio').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: false,
                    deferRender: true,
                    autoWidth: false,
                    data: self.recsratiocust,
                    columns: [
                    { "data": "1" },  // Column 1 remains unchanged
                    {
                        "data": "2",
                        render: function(data, type, row) {
                        return toRp(data);  // Apply Rp formatting
                        }
                    },
                    {
                        "data": "3",
                        render: function(data, type, row) {
                        return toRp(data);  // Apply Rp formatting
                        }
                    }
                    ],
                    columnDefs: [],
                    order: [[1, "desc"]],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                        "<'col-md-6'B>" +
                        "<'row'<'col-md-12 h9 mb-3'tr>>" +
                        "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                    {
                        extend: 'csvHtml5',
                        text: 'Export',
                        className: 'btn btn-primary',
                    }
                    ],
                });
                });

            let param = {
                pfunction : 'statuscust',
                filter : 'cust',
                dept: self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.card.custoff = response.data.rec[0].tc_nilai;
                self.card.custon = response.data.rec[1].tc_nilai;
            });
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },
        getDataPie(){
            let self = this;
            let param = {
                pfunction : 'bestcust',
                dept : self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.recCharts = response.data.rec;
                self.chartData = {
                    labels: response.data.rec.map(entry => entry.tm_name),
                    datasets: [
                        {
                            anchor: "center",
                            borderWidth: 0,
                            backgroundColor: response.data.rec.map(entry => self.getColor()),
                            data: response.data.rec.map(entry => entry.tm_qty),
                            borderColor: "#FFFFFF",
                            hoverBorderWidth: 5,
                        }
                    ]
                }
            });
            this.chartOptions = {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label:function (tooltipItem, data) {
                            return data['labels'][tooltipItem['index']];
                        },
                        footer:function (tooltipItem, data) {
                            let sum = 0;
                            let dataset = data.datasets[0];
                            let currentValue = dataset.data[tooltipItem[0].index];
                            let dataArr = dataset.data;
                            dataArr.map(data => {
                                sum += Number(data);
                            });
                            let percentage = (currentValue * 100 / sum).toFixed(2) + '%';
                            return "Persentase "+percentage;
                        }
                    }
                }
            }
        },
        setInit() {
            this.LoadData();
            this.getDataPie();
        },
        getColor(){
            let code = '#' + Math.floor(Math.random() * 16777215).toString(16);
            return code;
        },
        toRph(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
